define("discourse/plugins/discourse-locations/discourse/connectors/user-custom-preferences/map-location", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      const enabled = component.siteSettings.location_users_map;
      if (enabled) {
        (0, _runloop.scheduleOnce)("afterRender", () => {
          const $existingLocationInput = $(".control-group.pref-location");
          $(".control-group", component.element).insertAfter($existingLocationInput);
          $existingLocationInput.hide();
        });
      }
    },
    actions: {
      searchError(error) {
        this.set("error", error);
      }
    }
  };
});