define("discourse/plugins/discourse-locations/discourse/initializers/location-map-edits", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "I18n"], function (_exports, _pluginApi, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "locations-plugin";
  var _default = _exports.default = {
    name: "location-map-renderer",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        var _dec, _obj;
        const siteSettings = container.lookup("site-settings:main");
        if (siteSettings.location_sidebar_menu_map_link) {
          api.addCommunitySectionLink({
            name: "map",
            route: "discovery.map",
            title: _I18n.default.t("filters.map.title"),
            text: _I18n.default.t("filters.map.label")
          });
        }
        if (siteSettings.location_users_map) {
          api.addCommunitySectionLink({
            name: "users map",
            route: "locations.users-map",
            title: _I18n.default.t("directory.map.title"),
            text: _I18n.default.t("directory.map.title")
          });
        }

        // api.modifyClass("route:users", {
        //   pluginId: PLUGIN_ID,

        //   refreshQueryWithoutTransition: false,

        //   beforeModel(transition) {
        //     this.handleMapTransition(transition);
        //     this._super(transition);
        //   },

        //   handleMapTransition(transition) {
        //     const intent = transition.intent;
        //     const name = transition.targetName;
        //     const queryParams = intent.router.activeTransition.to.queryParams;

        //     if (intent.url === "/u" && siteSettings.location_users_map_default) {
        //       return this.replaceWith("users.user-map");
        //     }

        //     if (name === "users.user-map") {
        //       if (!queryParams.period || queryParams.period !== "location") {
        //         this.changePeriod(transition, "location");
        //       }
        //     } else if (name === "users.index") {
        //       if (queryParams.period === "location") {
        //         this.changePeriod(transition, "weekly");
        //       }
        //     }
        //   },

        //   changePeriod(transition, period) {
        //     // abort is necessary here because of https://github.com/emberjs/ember.js/issues/12169
        //     transition.abort();

        //     return this.replaceWith(transition.targetName, {
        //       queryParams: { period },
        //     });
        //   },

        //   renderTemplate() {
        //     this.render("users");
        //   },

        //   actions: {
        //     willTransition(transition) {
        //       this.handleMapTransition(transition);
        //       this._super(transition);
        //     },
        //   },
        // });

        api.modifyClass("component:user-card-contents", (_dec = (0, _decorators.default)("user"), (_obj = {
          pluginId: PLUGIN_ID,
          hasLocaleOrWebsite(user) {
            return user.geo_location || user.location || user.website_name || this.userTimezone;
          }
        }, (_applyDecoratedDescriptor(_obj, "hasLocaleOrWebsite", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasLocaleOrWebsite"), _obj)), _obj)));
      });
    }
  };
});