define("discourse/plugins/discourse-locations/discourse/components/location-selector", ["exports", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse-common/utils/decorators", "discourse/components/text-field", "discourse-common/lib/raw-templates"], function (_exports, _locationUtilities, _decorators, _textField, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _textField.default.extend((_dec = (0, _decorators.observes)("loading"), (_obj = {
    autocorrect: false,
    autocapitalize: false,
    classNames: "location-selector",
    context: null,
    didInsertElement() {
      this._super();
      let self = this;
      const location = this.get("location.address");
      let val = "";
      if (location) {
        val = location;
      }
      $(self.element).val(val).autocomplete({
        template: (0, _rawTemplates.findRawTemplate)("javascripts/location-autocomplete"),
        single: true,
        updateData: false,
        dataSource: function (term) {
          let request = {
            query: term
          };
          const context = self.get("context");
          if (context) {
            request["context"] = context;
          }
          self.set("loading", true);
          return (0, _locationUtilities.geoLocationSearch)(request, self.siteSettings.location_geocoding_debounce).then(result => {
            const defaultProvider = self.siteSettings.location_geocoding_provider;
            const geoAttrs = self.get("geoAttrs");
            const showType = self.get("showType");
            let locations = [];
            if (!result.locations || result.locations.length === 0) {
              locations = [{
                no_results: true
              }];
            } else {
              locations = result.locations.map(l => {
                if (geoAttrs) {
                  l["geoAttrs"] = geoAttrs;
                }
                if (showType !== undefined) {
                  l["showType"] = showType;
                }
                return l;
              });
            }
            locations.push({
              provider: _locationUtilities.providerDetails[result.provider || defaultProvider]
            });
            self.set("loading", false);
            return locations;
          }).catch(e => {
            self.set("loading", false);
            this.searchError(e);
          });
        },
        transformComplete: function (l) {
          if (typeof l === "object") {
            self.set("location", l);
            const geoAttrs = self.get("geoAttrs");
            return (0, _locationUtilities.geoLocationFormat)(l, self.site.country_codes, {
              geoAttrs
            });
          } else {
            // hack to get around the split autocomplete performs on strings
            $(".location-form .ac-wrap .item").remove();
            $(".user-location-selector .ac-wrap .item").remove();
            return $(self.element).val();
          }
        },
        onChangeItems: function (items) {
          if (items[0] == null) {
            self.set("location", "{}");
          }
        }
      });
    },
    showLoadingSpinner() {
      const loading = this.get("loading");
      const $wrap = $(this.element).parent();
      const $spinner = $("<span class='ac-loading'><div class='spinner small'/></span>");
      if (loading) {
        $spinner.prependTo($wrap);
      } else {
        $(".ac-loading").remove();
      }
    },
    willDestroyElement() {
      this._super();
      $(this.element).autocomplete("destroy");
    }
  }, (_applyDecoratedDescriptor(_obj, "showLoadingSpinner", [_dec], Object.getOwnPropertyDescriptor(_obj, "showLoadingSpinner"), _obj)), _obj)));
});