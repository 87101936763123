define("discourse/plugins/discourse-locations/discourse/components/location-label-container", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/runloop"], function (_exports, _decorators, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("topic.location.geo_location"), _dec2 = (0, _decorators.default)("locationAttrs", "geoAttrs"), (_obj = {
    classNames: ["location-label-container"],
    locationAttrs: [],
    geoAttrs: [],
    showMapToggle(geoLocation) {
      return geoLocation && this.siteSettings.location_topic_map;
    },
    opts(locationAttrs, geoAttrs) {
      let opts = {};
      if (locationAttrs) {
        opts["attrs"] = locationAttrs;
      }
      if (geoAttrs) {
        opts["geoAttrs"] = geoAttrs;
      }
      return opts;
    },
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    outsideClick(e) {
      if (!this.isDestroying && !($(e.target).closest(".map-expand").length || $(e.target).closest(".map-attribution").length || $(e.target).closest(".location-topic-map").length)) {
        this.set("showMap", false);
      }
    },
    actions: {
      showMap() {
        this.toggleProperty("showMap");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "showMapToggle", [_dec], Object.getOwnPropertyDescriptor(_obj, "showMapToggle"), _obj), _applyDecoratedDescriptor(_obj, "opts", [_dec2], Object.getOwnPropertyDescriptor(_obj, "opts"), _obj)), _obj)));
});